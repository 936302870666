<template>
    <div style="padding-left:50px">
        <b-row>
          <b-col>
            <b-row class="mb-3">
                <b-input-group size="sm">
                    <b-input-group-prepend is-text class="firstLabel">
                        Nome:
                    </b-input-group-prepend>
                    <b-form-input id="client_name"
                                type="text"
                                name="client_name"
                                maxlength="50"
                                v-model="form.client_name"
                                placeholder="Digite o nome">
                    </b-form-input>
                </b-input-group>
            </b-row>
            <b-row class="mb-3">
                <b-input-group size="sm">
                    <b-input-group-prepend is-text class="firstLabel">
                        E-mail:
                    </b-input-group-prepend>
                    <b-form-input id="email"
                                type="text"
                                name="email"
                                v-model="form.email"
                                placeholder="Digite o email">
                    </b-form-input>
                </b-input-group>
            </b-row>
            <b-row class="mb-3">
                <b-input-group size="sm">
                    <b-input-group-prepend is-text class="firstLabel">
                        CPF:
                    </b-input-group-prepend>
                    <b-form-input id="client_document"
                                type="text"
                                name="client_document"
                                v-mask="['###.###.###-##']"
                                maxlength="14"
                                v-model="form.client_document"
                                placeholder="Digite o CPF">
                    </b-form-input>
                </b-input-group>
            </b-row>
            <b-row class="mb-3">
                <b-input-group size="sm">
                    <b-input-group-prepend is-text class="firstLabel">
                        Celular:
                    </b-input-group-prepend>
                    <b-form-input id="client_document"
                                type="text"
                                name="client_document"
                                v-model="form.phone"
                                placeholder="Digite o celular">
                    </b-form-input>
                </b-input-group>
            </b-row>
            <b-row class="mb-3">
                <b-input-group size="sm">
                    <b-input-group-prepend is-text class="firstLabel">
                        Portal site:
                    </b-input-group-prepend>
                    <b-form-input id="uniquename_partner"
                                type="text"
                                name="uniquename_partner"
                                v-model="form.uniquename_partner"
                                placeholder="Digite o nome do portal site">
                    </b-form-input>
                </b-input-group>
            </b-row>
            <b-row class="mb-3">
                <b-input-group size="sm">
                    <b-input-group-prepend is-text class="firstLabel">
                        Empresa:
                    </b-input-group-prepend>
                    <b-form-input id="nomeEmpresa"
                                type="text"
                                name="nomeEmpresa"
                                v-model="form.nomeEmpresa"
                                placeholder="Digite a empresa">
                    </b-form-input>
                </b-input-group>
            </b-row>
            <b-row class="mb-3">
                <b-input-group size="sm">
                    <b-input-group-prepend is-text class="firstLabel">
                        CNPJ:
                    </b-input-group-prepend>
                    <b-form-input id="cnpjEmpresa"
                                type="text"
                                name="cnpjEmpresa"
                                v-model="form.cnpjEmpresa"
                                placeholder="Digite o cnpj">
                    </b-form-input>
                </b-input-group>
            </b-row>
            <b-row class="mb-3">
                <b-input-group size="sm">
                    <b-input-group-prepend is-text class="firstLabel">
                        Data de inclusão:
                    </b-input-group-prepend>
                    <HotelDatePicker class="item-date-picker-utilization" format="YYYY-MM-DD"
                    @confirm="customDateConfirmed()"
                    @clear="form.date_start = form.date_end = ''"
                      v-model="customDate" valueType="format" range :clearable="true" confirm placeholder="Selecione o período"></HotelDatePicker>
                </b-input-group>
            </b-row>
            <b-row class="mb-3">
                <b-input-group size="sm">
                    <b-button type="button" variant="primary" size="sm" @click="search">
                      <v-wait for="inprocess">
                          <template slot="waiting">
                              Consultando...
                          </template>
                      </v-wait>
                      <span v-if="!processing">Consultar</span>
                    </b-button>
                </b-input-group>
            </b-row>
            <b-row class="mb-3">
                <b-input-group size="sm">
                    <br/>
                    <h4>Total de Registros: {{ (grids.default.items[0] && grids.default.items[0].totalCount) ? grids.default.items[0].totalCount : 0 }}</h4>
                </b-input-group>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-if="grids.default.loaded" class="mb-3">
              <div class="card">
                <div class="table-responsive">
                  <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                    <thead>
                      <tr>
                        <th class="text-center">ID Cliente</th>
                        <th class="text-center">Data do Cadastro</th>
                        <th class="w-1">Portal site</th>
                        <th class="w-1">Empresa</th>
                        <th class="w-1">CNPJ</th>
                        <th class="text-center">Nome</th>
                        <th class="text-center">Sobrenome</th>
                        <th class="text-center">CPF</th>
                        <th class="text-center">Email</th>
                        <th class="text-center">Celular</th>
                        <th class="text-center">Data de Nascimento</th>
                        <th class="text-center">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in grids.default.items" v-bind:key="index">
                        <td>{{item.id_cliente}}</td>
                        <td><div style="font-size:12px">{{item.dt_inclusao}}</div></td>
                        <td>{{item.uniquename_partner}}</td>
                        <td>{{item.nomeEmpresa}}</td>
                        <td>{{item.cnpjEmpresa}}</td>
                        <td>{{item.ds_nome}}</td>
                        <td>{{item.ds_sobrenome}}</td>
                        <td><span v-if="item.client_document != '1'">{{item.client_document}}</span></td>
                        <td>{{item.cd_email_login}}</td>
                        <td>{{item.ds_celular}}</td>
                        <td>{{item.dt_nascimento}}</td>
                        <td>
                            <div class="input-group-append" style="float:right;">
                                <button type="button" class="btn btn-primary">Ações</button>
                                <button data-toggle="dropdown" type="button" class="btn btn-primary dropdown-toggle"></button>
                                <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" @click.stop="edit(item.id_cliente,$event.target)" style="cursor:pointer">
                                    <i class="far fa-edit"></i> Editar
                                </a>
                                <a class="dropdown-item" @click="sendSenha(item.cd_email_login, item.uniquename_partner)" style="cursor:pointer">
                                    <i class="fas fa-lock"></i> Reenviar Senha
                                </a>
                                <!--div class="dropdown-divider"></!--div>
                                <a-- class="dropdown-item" @click="refund" style="cursor:pointer">
                                    <i class="fas fa-hand-holding-usd"></i> Reembolso
                                </a-->
                                </div>
                            </div>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-pagination @change="pagto" size="sm" v-if="grids.default.loaded && grids.default.total>grids.default.perPage" :total-rows="grids.default.total" v-model="grids.default.currentPage" :per-page="grids.default.perPage">
                  </b-pagination>
                </div>
              </div>


        </b-row>
    </div>
</template>

<script>
import Vue from "vue";
import VueHead from "vue-head";
import config from "@/config";
import { func } from "@/functions";
import { searchService } from "../../components/common/services/searchs";
import HotelDatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import { mask } from "vue-the-mask";

Vue.use(VueHead);

export default {
  mixins: [func],
  directives: { mask },
  components: {
    HotelDatePicker,
  },
  name: "webpurchase-list",
  head: {
    title: function() {
      return {
        inner: `Clientes | Admin`,
        separator: " | ",
        complement: "Lista de Clientes",
      };
    },
  },
  filters: {
  },
  computed: {
  },
  methods: {
    edit(id_cliente) {
      this.$router.push(`/client/edit/${id_cliente}`);
    },
    customDateConfirmed() {
          if (this.customDate.length > 1 && this.customDate[0]) {
              this.form.date_start = this.customDate[0];
              this.form.date_end = this.customDate[1];
          }
      },
    sendSenha(email, empresa){
    if(!email && !empresa){
        this.toastError('E-mail ou empresa não existe');
        return;
    }
    if (this.processing) return;
      this.processing = true;

      this.showWaitAboveAll();

      searchService
        .sendSenha(
          email,
          empresa
        )
        .then(
          (response) => {
            this.processing = false;
            this.hideWaitAboveAll();

            if (this.validateJSON(response)) {
                if(response.success){
                    this.toastSuccess(response.msg);
                }else{
                    this.toastError(response.msg);
                }
            }
          },
          (error) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.toastError("Falha na execução.");
          }
        );
    },
    pagto(to) {
      this.grids.default.currentPage = to;
      this.search();
    },
    search() {
      if (this.processing) return;
      this.processing = true;

      this.showWaitAboveAll();

      searchService
        .clients(
          this.form.email,
          this.form.client_name,
          this.form.client_document,
          this.form.phone,
          this.form.uniquename_partner,
          this.form.nomeEmpresa,
          this.form.cnpjEmpresa,
          this.form.date_start,
          this.form.date_end,
          this.grids.default.currentPage,
          this.grids.default.perPage
        )
        .then(
          (response) => {
            this.processing = false;
            this.hideWaitAboveAll();

            if (this.validateJSON(response)) {
              this.grids.default.loaded = true;
              this.grids.default.items = response;

              this.grids.default.currentPage =
                response.length == 0 ? 1 : response[0]["currentPage"];
              this.grids.default.total =
                response.length == 0
                  ? 0
                  : response[0][config.system.totalCount];
            }
          },
          (error) => {
            this.processing = false;
            this.hideWaitAboveAll();
            this.toastError("Falha na execução.");
          }
        );
    },
  },
  created() {
  },
  data() {
    return {
      processing: false,
      loading: false,
      customDate: [
          new Date(),
          new Date()
      ],
      form: {
        email: "",
        client_name: "",
        client_document: "",
        phone: "",
        dt_nascimento: "",
        uniquename_partner: "",
        nomeEmpresa: "",
        date_start: "",
        date_end: "",
        cnpjEmpresa: "",
      },
      grids: {
        default: {
          processing: false,
          loaded: false,
          total: 0,
          currentPage: 1,
          perPage: 10,
          items: [],
        },
      },
    };
  },
};
</script>

<style>
</style>
